import React from "react";
import TestimonialCarousel from "../../components/showTestimonials";

const Testimonials = () => {
  const data = [
    {
      name: "Rajesh Singh",
      testimonial:
        "I had a great experience with Rebnx Financial Services. They provided me with excellent guidance and support throughout the loan application process. Their efficiency and professionalism are commendable.",
      location: "Bengaluru",
      stars: 5
    },
    {
      name: "Deepak Paliwal",
      testimonial:
        "Rebnx Financial Services exceeded my expectations. They were transparent, efficient, and guided me through the entire loan process. I am grateful for their assistance and would trust them for any future financial needs.",
      location: "Bengaluru",
      stars: 5
    },
    {
      name: "Vivek Tiwari",
      testimonial:
        "I had a wonderful experience with Rebnx Financial Services. They were responsive, knowledgeable, and helped me secure a loan tailored to my needs. I would highly recommend their services to anyone in need of financial assistance.",
      location: "Bengaluru",
      stars: 5
    },
    {
      name: "Anita Desai",
      testimonial:
        "Rebnx Financial Services made my loan process seamless. The team was professional, understanding, and helped me secure a loan quickly. I highly recommend their services for anyone looking for financial assistance.",
      location: "Bengaluru",
      stars: 5
    },
  ];
  return <TestimonialCarousel data={data} />;
};

export default Testimonials;
