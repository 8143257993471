import React from 'react'
import InfoSection from '../../components/infoSection/infoSection'
import { aboutUsOne, aboutUsTwo } from './data'

const AboutUs = () => {
  return (
   <>
    <InfoSection {...aboutUsOne} imgWidth='80%' />
    <InfoSection {...aboutUsTwo} imgWidth='80%' />
   </>
  )
}

export default AboutUs