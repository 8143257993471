import styled from "styled-components";

export const InfoSec = styled.div`
  color: #fff;
  padding: 80px 0;
`;

export const InfoRow = styled.div<{ $imgStart?: string }>`
  display: flex;
  margin: 0 -15px -15px -15px;
  flex-wrap: wrap;
  flex-direction: ${({ $imgStart }) => ($imgStart ? "row-reverse" : "row")};
`;

export const InfoColumn = styled.div`
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  max-width: 50%;
  flex-basis: 50%;

  @media (min-width: 480px) and (max-width: 1200px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    min-width: 300px;
    padding-left: 8rem;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

export const TextWrapper = styled.div`
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 60px;

  @media (min-width: 480px) and (max-width: 1200px) {
    padding-bottom: 65px;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    padding-bottom: 65px;
  }
`;

export const ImgWrapper = styled.div<{ $start?: boolean }>`
  max-width: 555px;
  display: flex;
  
`;

export const TopLine = styled.div`
  font-size: 18px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  margin-bottom: 16px;
  color: #1c2237;
`;

export const Img = styled.img`
  padding-right: 0;
  border: 0;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
  max-height: 500px;
  width: ${({width}) => (width? width : '')}
  height: ${({height}) => (height? height : '')}
`;

export const Heading = styled.h1<{ $lightTextlightText?: boolean }>`
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  color: ${({ $lightTextlightText }) => ($lightTextlightText ? "#f7f8fa" : "#1c2237")};
`;

export const Subtitle = styled.p<{ $lightTextDesc?: boolean }>`
  max-width: 440px;
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
  color: ${({ $lightTextDesc }) => ($lightTextDesc ? "#a9b3c1" : "#1c2237")};
  text-align: justify;
`;
