import React, { useRef, useState } from "react";
import { IoCall } from "react-icons/io5";
import {
  InfoColumn,
  InfoRow,
  InfoSec,
} from "./infoSection/infoSection.elements";
import { Container } from "../globalStyles";
import emailjs from "@emailjs/browser";
import InputComponent from "./inputComponent";
import { RiWhatsappLine, RiMailAddFill } from "react-icons/ri";
import { FaClock } from "react-icons/fa6";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoaderComponent from "./loading";


const ContactUs = () => {
  const [loading ,setLoading] = useState(false)
  const [formData, setFormData] = useState<{ [key: string]: string }>({});
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});
  const [disableBtn, setDisableBtn] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);

  const onChange = (name: string, value: string) => {
    
    setFormData({
      ...formData,
      [name]: value,
    });
    const newErrors = { ...formErrors };
    delete newErrors[name];
    setFormErrors(newErrors);
  };

  const checkErrors = () => {
    const newErrors: { [key: string]: string } = {};
    let isError = false;
    if (!formData["email"]) {
      newErrors["email"] = "Email is required field!";
      isError = true;
    }
    if (!formData["phone"]) {
      newErrors["phone"] = "Phone is required field!";
      isError = true;
    }
    if (!formData["name"]) {
      newErrors["name"] = "Name is required field!";
      isError = true;
    }
    setFormErrors(newErrors);
    return isError;
  };

  const showToast = (success: boolean, message: string) => {
    toast(message, {
      type: success? 'success': "error"
    })
  }

  const sendEmail = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (checkErrors()) {
      return;
    }

    setDisableBtn(true)
    setLoading(true)
    if (formRef.current) {
      emailjs
        .sendForm("service_l4tyrns", "template_56818wf", formRef.current, {
          publicKey: "qM3LNiLTJ1-uPWN8I",
        })
        .then(
          () => {
            console.log("SUCCESS!");
            showToast(true,'Email send successfully!');
            setDisableBtn(false)
            
          },
          (error) => {
            console.log("FAILED...", error);
            showToast(false,error?.text || 'Somrthing went wrong! Please try again later');
          }
        ).finally(()=>{
          setDisableBtn(false)
          setLoading(false)
        });
    }
  };

  return (
    <InfoSec className="!bg-secondary">
      {loading && <LoaderComponent />}
      <Container>
        <div className="flex justify-center mb-4">
          <p className="text-4xl uppercase font-bold">Contact</p>
        </div>
        <InfoRow className="items-center">
          <InfoColumn>
            <div>
              <p >Bengaluru, Karnataka, India</p>
              <a href="/" className="flex items-center gap-x-2">
                <span>
                  <IoCall />
                </span>{" "}
                +91-988-626-0775 - Company
              </a>
              <a href="/" className="flex items-center gap-x-2">
                <span>
                  <RiWhatsappLine />
                </span>{" "}
                +91-988-626-0775 - Company
              </a>
              <a href="/" className="flex items-center gap-x-2">
                <span>
                  <RiMailAddFill />
                </span>{" "}
                contact@rebnxfs.com
              </a>
              <div className="flex items-center gap-x-2">
                <span>
                  <FaClock />
                </span>{" "}
                Monday to Saturday: 10AM to 8PM
              </div>
            </div>
          </InfoColumn>
          <InfoColumn>
            <form className="w-full" ref={formRef} onSubmit={sendEmail}>
              <div className="flex gap-x-4 py-4 flex-1">
                {/* <input
                  type="text"
                  placeholder="Name"
                  onChange={(e) => onChange(e.target.name, e.target.value)}
                  name="name"
                  value={formData?.["name"] || ''}
                  className="input input-bordered  w-full"
                /> */}
                <InputComponent
                  onChange={(name, value) => onChange(name, value)}
                  value={formData?.["name"] || ""}
                  placeholder="Name"
                  name="name"
                  error={formErrors?.["name"] || ""}
                />
                <InputComponent
                  onChange={(name, value) =>{
                    if (/^\d+$/.test(value) || value === '') {
                      onChange(name, value)
                    }
                     
                  }}
                  max={10}
                  value={formData?.["phone"] || ""}
                  placeholder="Phone"
                  name="phone"
                  error={formErrors?.["phone"] || ""}
                />
              </div>

              <InputComponent
                onChange={(name, value) => onChange(name, value)}
                value={formData?.["email"] || ""}
                placeholder="Email"
                name="email"
                error={formErrors?.["email"] || ""}
              />
              <div className=" my-4">
                <textarea
                  placeholder="Message"
                  name="message"
                  value={formData?.["message"] || ""}
                  onChange={(e) => onChange(e.target.name, e.target.value)}
                  className="textarea textarea-bordered w-full"
                />
                {formErrors?.["message"] && (
                  <p className="text-red-700 ">{formErrors?.["message"]}</p>
                )}
              </div>
              <div className="my-4 flex justify-center">
                <button className="btn btn-wide" disabled={disableBtn}>Contact Us</button>
              </div>
            </form>
          </InfoColumn>
        </InfoRow>
      </Container>
      <ToastContainer className={' z-50'} />
    </InfoSec>
  );
};

export default ContactUs;
