export const aboutUsOne = {
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    topLine: '',
    headline: 'About US',
    description: `Rebnx Financial Services is a trusted finance agency dedicated to empowering individuals and businesses with tailored financial solutions. We specialize in providing fast, flexible, and fair loans to meet a wide range of needs.`,
    moreDescriptions: [`Our commitment to exceptional customer service and transparent processes ensures a seamless borrowing experience. At Rebnx, we believe in building lasting relationships and unlocking opportunities with every transaction, helping our clients achieve their financial goals with confidence.`],
    buttonLabel: 'Get Started',
    imgStart: '',
    img: './about-us.svg',
    alt: 'Credit Card',
    start: false,
  };

  export const aboutUsTwo = {
    primary: true,
    lightBg: true,
    lightTopLine: false,
    lightText: true,
    lightTextDesc: true,
    topLine: '',
    headline: 'Mission Statement',
    description: `At Rebnx Financial Services, our mission is to provide reliable, transparent, and tailored financial solutions that empower individuals and businesses to achieve their financial goals. We are committed to delivering exceptional customer service, fostering long-term relationships, and ensuring seamless access to loans that support growth and financial stability.`,
    buttonLabel: 'Get Started',
    imgStart: '',
    alt: 'Credit Card',
    start: false,
    secondTextData : {
        lightBg: false,
        lightTopLine: true,
        lightText: true,
        lightTextDesc: true,
        topLine: '',
        headline: 'Vision Statement',
        description:
          'Our vision is to be the leading financial partner of choice, recognized for our innovation, integrity, and dedication to customer success. We aspire to transform the lending landscape by offering accessible and efficient financial services, thereby contributing to the economic development and prosperity of the communities we serve.',
    }
  };