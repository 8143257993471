import React from 'react'
import FAQComponent from '../components/FAQ/FAQComponent'

const FAQ = () => {
    const data = [ {
        question: 'What types of loans does Rebnx Financial Services offer?',
        answer: `Rebnx Financial Services offers a range of loan products under the categories of "Individual Loans" and "Business Loans". We will soon be launching our flagship "Peer-to-Peer (P2P) Loans" shortly.`
      }]
  return (
    <div>
        {/* <FAQComponent data={data}/> */}
    </div>
  )
}

export default FAQ