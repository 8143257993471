import React, { useState, useEffect } from "react";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  NavIcon,
  HamburgerIcon,
  NavMenu,
  NavItem,
  NavLinks,
  NavItemBtn,
  NavBtnLink,
} from "./navbar.elements";
import { FaTimes, FaBars } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import { Button } from "../../globalStyles";
import { Helmet } from 'react-helmet';

function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const [homeClick, setHomeClick] = useState(false);
  const [servicesClick, setServicesClick] = useState(false);
  const [productsClick, setProductsClick] = useState(false);

  const handleHomeClick = () => {
    setHomeClick(true);
    setProductsClick(false);
    setServicesClick(false);
  };
  const handleServicesClick = () => {
    setHomeClick(false);
    setProductsClick(false);
    setServicesClick(true);
  };
  const handleProductsClick = () => {
    setHomeClick(false);
    setProductsClick(true);
    setServicesClick(false);
  };

  const handleClick = () => setClick(!click);

  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    // so if the screensize is <= 960px then set button state to false
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener("resize", showButton);

  return (
    <>
     <Helmet>
      <title>Rebnx Financial Services | Loans and Investments</title>
      <meta name="description" content="Rebnx Financial Services offers a variety of loans and investment options tailored to fit your specific requirements. Visit rebnxfs.com to learn more." />
      <meta name="keywords" content="financial services, loans, investments, personal loans, home loans, auto loans, business loans, student loans, mutual funds, stocks, bonds, retirement plans, real estate investments" />
    </Helmet>
      <IconContext.Provider value={{ color: "#fff" }}>
        <Nav className="bg-tertiary">
          <NavbarContainer>
            <NavLogo to="/">
              <img
                src="./company-logo.png"
                width={50}
                height={60}
                alt="company-logo"
              />
              {/* <p className='mb-2'>Rebnx</p> */}
            </NavLogo>
            <HamburgerIcon onClick={handleClick}>
              {click ? <FaTimes /> : <FaBars />}
            </HamburgerIcon>
            <NavMenu onClick={handleClick} $click={click} className="bg-tertiary">
              <NavItem onClick={handleHomeClick}>
                <NavLinks to="/" onClick={closeMobileMenu}>
                  Home
                </NavLinks>
              </NavItem>

              <NavItem onClick={handleServicesClick}>
                <NavLinks to="/about-us" onClick={closeMobileMenu}>
                  About Us
                </NavLinks>
              </NavItem>

              <NavItem onClick={handleServicesClick}>
                <NavLinks to="/services" onClick={closeMobileMenu}>
                  Services
                </NavLinks>
              </NavItem>

              <NavItem onClick={handleServicesClick}>
                <NavLinks to="/testimonials" onClick={closeMobileMenu}>
                  Testimonials
                </NavLinks>
              </NavItem>

              <NavItem onClick={handleServicesClick}>
                <NavLinks to="/contact-us" onClick={closeMobileMenu}>
                  Contact
                </NavLinks>
              </NavItem>

              <NavItem onClick={handleServicesClick}>
                <NavLinks to="/faq" onClick={closeMobileMenu}>
                  F.A.Q
                </NavLinks>
              </NavItem>

              <NavItem onClick={handleServicesClick}>
                <NavLinks to="/blogs" onClick={closeMobileMenu}>
                  Blog
                </NavLinks>
              </NavItem>

              {/* <NavItem onClick={handleProductsClick} >
                            <NavLinks to='/Products' onClick={closeMobileMenu}>
                                Products
                            </NavLinks>
                        </NavItem>

                        <NavItemBtn >
                            {button ? (
                                <NavBtnLink to='/sign-up'>
                                    <Button $primary>SIGN UP</Button>
                                </NavBtnLink>
                            ) : (
                                <NavBtnLink to='/sign-up'>
                                    <Button onClick={closeMobileMenu} $fontBig $primary>SIGN UP</Button>
                                </NavBtnLink>
                            )}
                            
                        </NavItemBtn> */}
            </NavMenu>
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  );
}

export default Navbar;
