import React from "react";
import { Link } from "react-router-dom";

type props = {
  title: string;
  description: string;
  image: string;
  redirectTo: string
};

const Cards = ({ title, description, image, redirectTo }: props) => {
  return (
    <div className="card w-96 glass">
      <figure>
        <img src={image} alt="loan" width={"75%"} className="!min-h-60" />
      </figure>
      <div className="card-body flex flex-col items-center">
        <h2 className="card-title text-white">{title}</h2>
        <p>{description}</p>
        <Link to={redirectTo}>
          <div className="card-actions justify-end">
            <button className="btn btn-secondary">Learn now!</button>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Cards;
