import React, { useState } from "react";
import Slider from "react-slick";
import styled from "styled-components";
import Ratings from "react-star-ratings";

interface Testimonial {
  name: string;
  testimonial: string;
  location: string;
  stars: number;
}

const TestimonialCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin: 10px;
  width: 80%;
  text-align: center;
  cursor: pointer; /* Optional: Hover effect for pausing */
`;

const Name = styled.h3`
  font-size: 1.2rem;
  margin-bottom: 10px;
`;

const TestimonialText = styled.p`
  font-size: 1rem;
  line-height: 1.5;
`;

const Location = styled.span`
  font-style: italic;
  font-size: 0.8rem;
  margin-top: 10px;
  margin-right: 1rem;
`;

const StarIcon = styled.i`
  color: gold;
  font-size: 1.2rem;
`;

const Navigation = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const Button = styled.button`
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: #ddd;
  cursor: pointer;
  margin: 0 5px;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: #ccc;
  }
`;

const TestimonialCarousel = ({ data }: { data: Testimonial[] }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const settings = {
    dots: false, // Display navigation dots
    infinite: true, // Enable carousel looping
    autoplay: false, // Disable autoplay
    autoplaySpeed: 3000, // Optional: Set autoplay speed (if enabled)
    slidesToShow: 1, // Show one testimonial at a time
    slidesToScroll: 1, // Scroll one testimonial at a time
  };

  return (
    <div className="py-16 bg-primary text-white">
      <Slider {...settings} className="px-8" >
        {data.map((item, index) => (
          <TestimonialCard key={index}>
            <Name>{item.name}</Name>
            <TestimonialText>{item.testimonial}</TestimonialText>
            <Location>{item.location}</Location>
            <Ratings
              rating={item.stars}
              starRatedColor="yellow"
              numberOfStars={5}
              starDimension={"20px"}
            />
          </TestimonialCard>
        ))}
      </Slider>
    </div>
  );
};

export default TestimonialCarousel;
