import React from "react";

interface props {
    value: string
    error?: string
    onChange: (name:string, value:string) => void
    placeholder?: string;
    name?: string;
    max?: number;
}

const InputComponent = (props: props) => {
  return (
    <div className="w-full">
      <input
        type='text'
        placeholder={props.placeholder}
        onChange={(e) => props.onChange(e.target.name, e.target.value)}
        name={props.name}
        value={props.value}
        maxLength={props.max}
        className={`${props.error? "input input-error input-bordered w-full": "input input-bordered w-full"}`}
      />
      {props.error && <p className="text-red-700 ">
            {props.error}
        </p>}
    </div>
  );
};

export default InputComponent;
