import React from "react";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import { Routes, Route } from "react-router";
import GlobalStyles from "./globalStyles";
import ScrollToTop from "./components/scrollToTop";
// import Navbar from "./components/navbar/navbar";
import Home from "./pages/HomePage/Home";
import Navbar from "./components/navbar/navbar";
import Footer from "./components/footer/Footer";
import AboutUs from "./pages/AboutUs/aboutUs";
import Services from "./pages/Services/Services";
import ContactUs from "./components/contactUs";
import Testimonials from "./pages/Testimonials/testimonials";
import IndividualLoans from "./pages/individualLoans";
import BusinessLoans from "./pages/businessLoans";
import FAQ from "./pages/FAQ";

function App() {
  return (
    <BrowserRouter>
      <GlobalStyles />
      <ScrollToTop />
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/testimonials" element={<Testimonials />} />
        <Route path="/individual-loans" element={<IndividualLoans />} />
        <Route path="/business-loans" element={<BusinessLoans />} />
        <Route path="/investments" element={<Testimonials />} />
        <Route path="/faq" element={<FAQ />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
