import React from "react";

type props = {
  title: string;
  types: {
    img: string;
    title: string;
    description: string;
  }[];
};

const CircleImage = ({
  img,
  title,
  description,
}: {
  img: string;
  title: string;
  description: string;
}) => {
  return (
    <div className="w-96 flex flex-col items-center p-4 text-white">
      <div className="avatar">
        <div className="w-24 rounded-full">
          <img src={img} />
        </div>
      </div>
      <div className="flex flex-col items-center">
        <p className="uppercase font-bold mt-2 text-xl">{title}</p>
        <p className="mt-2 text-center">{description}</p>
      </div>
    </div>
  );
};

const ServiceType = ({ title, types }: props) => {
  return (
    <div className="bg-secondary text-white">
      <center className="py-8">
        <h1 className=" font-bold text-4xl underline">{title}</h1>
      </center>
      <div className="flex flex-wrap gap-x-4 w-full justify-around">
        {types.map((ele) => {
          return <CircleImage {...ele} />;
        })}
      </div>
    </div>
  );
};

export default ServiceType;
