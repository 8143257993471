import React from "react";
import {
  InfoSec,
  InfoRow,
  InfoColumn,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  ImgWrapper,
  Img,
} from "./infoSection.elements";
import { Container, Button } from "../../globalStyles";
import { Link } from "react-router-dom";

type props = {
  primary?: boolean;
  lightBg?: boolean;
  topLine?: string;
  lightTopLine?: boolean;
  lightText?: boolean;
  lightTextDesc?: boolean;
  headline?: string;
  description?: string;
  buttonLabel?: string;
  img?: string;
  alt?: string;
  imgStart?: string;
  start?: boolean;
  btnLink?: string;
  imgWidth?: string;
  imgHeight?: string;
  secondTextData?: {
    topLine?: string;
    lightTopLine?: boolean;
    lightText?: boolean;
    lightTextDesc?: boolean;
    headline?: string;
    description?: string;
    };
  moreDescriptions?: string[]
  containerClass?:string
};

const InfoSection = ({
  primary,
  lightBg,
  topLine,
  lightTopLine,
  lightText,
  lightTextDesc,
  headline,
  description,
  buttonLabel,
  img,
  alt,
  imgStart,
  start,
  btnLink,
  imgWidth,
  imgHeight,
  secondTextData,
  moreDescriptions
}: props) => {
  return (
    <>
      <InfoSec className={`${lightBg? "bg-secondary":"bg-primary"}`}>
        <Container>
          <InfoRow $imgStart={imgStart} >
            <InfoColumn>
              <TextWrapper>
                <TopLine >{topLine}</TopLine>
                <Heading >{headline}</Heading>
                <Subtitle >
                  {description}
                </Subtitle>
                {moreDescriptions?.map((ele,i)=>{
                  return <Subtitle key={i}>
                  {ele}
                </Subtitle>
                })}
                
                {btnLink && (
                  <Link to={btnLink}>
                    <Button $big $fontBig $primary={primary}>
                      {buttonLabel}
                    </Button>
                  </Link>
                )}
              </TextWrapper>
            </InfoColumn>
            {img && (
              <InfoColumn>
                <ImgWrapper $start={start}>
                  <Img
                    src={img}
                    alt={alt}
                    width={imgWidth}
                    height={imgHeight}
                  />
                </ImgWrapper>
              </InfoColumn>
            )}
            {secondTextData && (
              <InfoColumn>
                <TextWrapper>
                  <TopLine >{secondTextData.topLine}</TopLine>
                  <Heading >{secondTextData.headline}</Heading>
                  <Subtitle >
                    {secondTextData.description}
                  </Subtitle>
                </TextWrapper>
              </InfoColumn>
            )}
          </InfoRow>
        </Container>
      </InfoSec>
    </>
  );
};

export default InfoSection;
