import React from "react";
import ServiceType from "../components/serviceType";

const IndividualLoans = () => {
  return (
    <div className="py-8">
      <ServiceType
        title="INDIVIDUAL LOANS"
        types={[
          {
            img: "https://static1.s123-cdn-static-a.com/uploads/2031/400_5a15ab3119d59.jpg",
            title: "Personal Loans",
            description:
              "Our personal loan service offers competitive interest rates and flexible repayment plans.",
          },
          {
            img: "https://static1.s123-cdn-static-a.com/ready_uploads/media/2687889/400_5e05d5d8cdea7.jpg",
            title: "Home Loans",
            description: `With our home loan service, you can enjoy expert guidance and support throughout the entire borrowing journey.`,
          },
          {
            img: "https://static1.s123-cdn-static-a.com/ready_uploads/media/23065/400_5cdc2f1a86b9c.jpg",
            title: "Vehicle Loans",
            description: `Experience quick and easy approval for your vehicle loan, tailored to your financial needs.`,
          },
          {
            img: "https://static1.s123-cdn-static-a.com/ready_uploads/media/2695097/400_5e05cd1631769.jpg",
            title: "Student Loans",
            description: `By choosing student loan, students can alleviate financial stress and focus on their studies, knowing that they have a reliable and supportive financing solution.`,
          },
          {
            img: "https://static1.s123-cdn-static-a.com/ready_uploads/media/181567/400_5ceba3b9849b8.jpg",
            title: "Gold Loans",
            description: `Unlock the value of your gold assets and meet your financial needs with our Gold Loan service.`,
          },
        ]}

      />
    </div>
  );
};

export default IndividualLoans;
