import React from "react";
import Cards from "../../components/cards";
import { Helmet } from "react-helmet";

const Services = () => {
  return (
    <div className="bg-secondary">
      <Helmet>
        <title>Our Services | Rebnx Financial Services</title>
        <meta
          name="description"
          content="Explore the comprehensive range of services offered by Rebnx Financial Services, including various loan and investment options."
        />
        <meta
          name="keywords"
          content="financial services, loans, investments, personal loans, home loans, auto loans, business loans, student loans, mutual funds, stocks, bonds, retirement plans, real estate investments"
        />
      </Helmet>
      <div className="p-8 flex justify-around gap-x-8">
        <Cards
          title="Individual Loans"
          description=""
          image="./person.svg"
          redirectTo="/individual-loans"
        />
        <Cards
          title="Business Loans"
          description=""
          image="./business.svg"
          redirectTo="/business-loans"
        />
        {/* <Cards title='Investments' description='' image='./investment.svg' redirectTo="/investments" /> */}
      </div>
    </div>
  );
};

export default Services;
