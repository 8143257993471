import React from "react";
import ServiceType from "../components/serviceType";

const BusinessLoans = () => {
  return (
    <div className="py-8">
      <ServiceType
        title="BUSINESS LOANS"
        types={[
          {
            img: "https://static1.s123-cdn-static-a.com/ready_uploads/media/19452/400_5cdbb3013050f.jpg",
            title: "BUSINESS LOANS",
            description:
              "Our business loan service provides you with the financial assistance you need to grow your business and pursue new opportunities.",
          },
          {
            img: "	https://static1.s123-cdn-static-a.com/ready_uploads/media/3480506/400_5e2620949bfbd.jpg",
            title: "EQUIPMENT LOANS",
            description: `Get quick access to essential equipment without the long-term commitment through our reliable equipment loan service.`,
          },
          {
            img: "https://static1.s123-cdn-static-a.com/ready_uploads/media/6570451/400_5f7caa135cc1c.jpg",
            title: "START-UP LOANS",
            description: `Start-Up Loan provides flexible and tailored funding options, empowering aspiring entrepreneurs to turn their visions into successful ventures.`,
          },
          {
            img: "https://static1.s123-cdn-static-a.com/ready_uploads/media/13920750/400_660c5f15a246f.jpg",
            title: "COMMERCIAL VEHICLE LOANS",
            description: `Our streamlined approval process ensures quick access to financing for your commercial vehicle needs.`,
          },
        ]}

      />
    </div>
  );
};

export default BusinessLoans;
