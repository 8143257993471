import React from "react";
import {
  RiInstagramLine,
  RiTwitterFill,
  RiLinkedinBoxFill,
  RiFacebookBoxFill,
  RiWhatsappLine,
} from "react-icons/ri";

export const Footer = () => {
  const companyLogo = "./company-logo.png";
  return (
    <footer className=" py-4 px-8 flex justify-between items-center">
      <div className="flex flex-col items-center">
        <img src={companyLogo} width={60} alt="logo" />
        <p  className="mt-2">Rebnx Financial Services</p>
        <p>

         Copyright © 2024 All rights reserved
        </p>
      </div>
      <div className="flex flex-col gap-y-4">
        <div>
          <h2 className=" uppercase text-lg">Contact Us</h2>
          <p className=" text-sm">+91 9886260775</p>
          <p className=" text-sm">contact@rebnxfs.com</p>
        </div>
        <div>
          <h2 className=" uppercase text-lg flex items-center gap-x-2">
            Check Us Out{" "}
          </h2>
          <div className="flex gap-x-4">
            <RiWhatsappLine color="#25D366" />
            <RiFacebookBoxFill />
            <RiLinkedinBoxFill />
            <RiInstagramLine />
            <RiTwitterFill />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
